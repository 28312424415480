<template>
  <v-container
    tag="footer"
    class="py-4 text-center text-sm text-gray-400"
  >
    Version {{ config.public.version }} © {{ new Date().getFullYear() }} AYSA OU
  </v-container>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()
</script>
